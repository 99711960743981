import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const service = axios.create({
  baseURL: '', 
  // withCredentials: true, 
  timeout: 10000 
})

service.interceptors.request.use(
    (  config: any) => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return config
  },
    (  error: any) => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
    (  response: { data: any; }) => {
    const res = response.data
    return res
  },
    (  error: string) => {
    console.log('err' + error) // for debug

    return Promise.reject(error)
  }
)

export default service
