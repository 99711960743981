import request from "@/util/request";
import qs from 'qs'
const v1BaseUrl = "/v1-api";

// 获取验证码
export function getCaptchaCode() {
    return request({
        url: v1BaseUrl + "/jb/getCaptcha",
        method: "get"
    });
}

// 保存举报信息
export function saveReport(data: any) {
  return request({
    url: v1BaseUrl + "/jb/saveInfo",
    method: "post",
    data: qs.stringify(data)
    // data: data
  });
}

// 分享-横屏视频
// export function shareVideo(data) {
//   return request({
//     url: v1BaseUrl + "/video/share/video/detail2022/" + data.id,
//     method: "get",
//   });
// }

// // 分享-见文
// export function shareArticle(data) {
//   return request({
//     url: v1BaseUrl + "/article/share/article/" + data.id,
//     method: "post"
//   });
// }
